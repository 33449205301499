import React, { useContext, useRef, useState, useEffect } from "react";

const SalesforceContext = React.createContext({
  pushEvent: (type?: string, options?: any) => {},
  setEmail: (email: string) => {},
  orgId: "",
  email: "",
});

const isValidEmail = (str: string) => {
  const reg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  return reg.test(str);
};

export const SalesforceProvider = ({ children, orgId }) => {
  const [email, setEmail] = useState("");

  const pushEvent = (type = "trackPageView", options?: any) => {
    if (!(window as any)._etmc) return;
    const { _etmc } = window as any;
    _etmc.push(["setOrgId", orgId]);
    if (email) {
      _etmc.push(["setUserInfo", { email }]);
    }
    _etmc.push([type, options]);
  };

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
      });
      let newsletter = "";

      if ((params as any).e) {
        newsletter = atob((params as any).e);
      }

      if (email) {
        localStorage.setItem("salesforce/userInfo", email);
      } else if (newsletter && isValidEmail(newsletter)) {
        setEmail(newsletter);
      } else {
        setEmail(localStorage.getItem("salesforce/userInfo") || "");
      }
    }
  }, [email]);

  return (
    <SalesforceContext.Provider value={{ pushEvent, setEmail, email, orgId }}>
      {children}
    </SalesforceContext.Provider>
  );
};

export default function useSalesforce() {
  const { pushEvent, email, setEmail, orgId } = useContext(SalesforceContext);
  const eventSent = useRef(false);

  const initialSalesforceEvent = () => {
    setTimeout(() => pushEvent("trackPageView", { email, orgId }), 2000);
  };

  useEffect(() => {
    if (!eventSent.current) {
      window.addEventListener("ucEvent", initialSalesforceEvent);
    }
    eventSent.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pushEvent,
    setEmail,
  };
}
